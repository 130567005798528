import axios from 'axios';
import { ANSWER_COMPONENT_TAB_OPTIONS, EVIDENCE_ATTACH, EVIDENCE_DELETE, REQUIREMENTS_SUMMARY_TAB_OPTIONS, REQUIREMENT_QUESTIONS_TAB_OPTIONS } from 'pages/AssessmentQuestionnaire/constants';
import { getErrorObject, getErrorPayload } from 'utils/apiUtils';
import {
  getAssessmentEntireQuestionnaireResponseBody,
  getAssessmentQuestionnaireRequirementGroupSearchBody,
  getAssessmentQuestionnaireResponseBody,
  getEvidenceDetailsBody,
  getResetQuestionnaireBody,
  getShowMeRedirectionModallDetailsBody,
  searchAssessmentByIdBody,
} from 'utils/assessmentQuestionnaireUtils';
import { setErrors } from './errorHandler';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { searchAssessmentOverviewPortalAssessmentDetails } from './assessmentsOverview';
import { GENERATE_COPILOT_RESPONSE_TIMEOUT, NO_VALUE, YES_VALUE } from 'constants/constants';
import { isEmpty, isNil } from 'lodash';

export const SET_REQUIREMENT_QUESTIONS_TAB = '@@assessmentQuestionnaire/SET_REQUIREMENT_QUESTIONS_TAB';

export const setRequirementQuestionsTabValue =
  (value = REQUIREMENT_QUESTIONS_TAB_OPTIONS.ALL) =>
    async (dispatch) => {
      dispatch({
        type: SET_REQUIREMENT_QUESTIONS_TAB,
        payload: value,
      });
    };

export const SET_ANSWER_COMPONENT_TAB = '@@assessmentQuestionnaire/SET_ANSWER_COMPONENT_TAB';

export const setAnswerComponentTabValue =
  (value = ANSWER_COMPONENT_TAB_OPTIONS.ALL) =>
    async (dispatch) => {
      dispatch({
        type: SET_ANSWER_COMPONENT_TAB,
        payload: value,
      });
    };

export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE';

export const getAssessmentQuestionnaireRequirement =
  (assessmentQuestionnaireId = null, callback) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING });
      // keep below code as backup in case of any issue
      // const body = getAssessmentQuestionnaireRequirementSearchBody(assessmentQuestionnaireId);
      try {
        const response = await axios.get(`assessment-questionnaires/${assessmentQuestionnaireId}/requirements`);
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
          payload: response.data?.Data || [],
        });
        if (callback) {
          callback(response.data?.Data || []);
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
          payload: getErrorPayload(err),
        });
      }
    };

export const updateRequirementGroupAfterSingleSave = (reqGrpId, reqId, countOfParentQuestionAnswers) => async (dispatch, getState) => {
  const requirementGroupList = getState().assessmentQuestionnaire.requirementList;

  const updatedRequirementGroupList = requirementGroupList.map((reqGrp) => {
    if (reqGrp.RequirementGroupId === reqGrpId) {
      const updatedRequirementList = reqGrp.RequirementList.map((req) => {
        if (req.RequirementId === reqId) {
          return { ...req, CountOfParentQuestionAnswers: countOfParentQuestionAnswers };
        }
        return req;
      });
      return { ...reqGrp, RequirementList: updatedRequirementList };
    }
    return reqGrp;
  });

  dispatch({
    type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
    payload: updatedRequirementGroupList,
  });
};

export const SET_SELECTED_REQUIREMENT = '@@assessmentQuestionnaire/SET_SELECTED_REQUIREMENT';

export const setSelectedRequirement =
  (value = {}, showSummaryScreen = true) =>
    async (dispatch) => {
      dispatch({
        type: SET_SELECTED_REQUIREMENT,
        payload: value,
        showSummaryScreen: showSummaryScreen,
      });
    };

export const SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_LOADING = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_LOADING';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_SUCCESS = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_SUCCESS';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_FAILURE = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_FAILURE';

export const getAssessmentQuestionnaireResponse = (assessmentId, assessmentQuestionnaireId, requirementId, excludeCanOrgUploadEvidence, callback) => async (dispatch, getState) => {
  dispatch({ type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_LOADING });

  const requirementList = getState().assessmentQuestionnaire.requirementList;
  const isRequirementGroup = requirementList[0]?.isRequirementGroup === YES_VALUE;
  const haveOnlyOneReq = requirementList?.length === 1;

  let requirementGroupId = null;
  if (isRequirementGroup && !haveOnlyOneReq) {
    requirementGroupId = requirementList?.find(reqGrp => reqGrp.RequirementList?.find((it) => it.RequirementId === Number(requirementId)))?.RequirementGroupId;
  }

  if (!haveOnlyOneReq) {
    const body = getAssessmentQuestionnaireResponseBody(requirementId, excludeCanOrgUploadEvidence, requirementGroupId);

    try {
      const response = await axios.post(`assessment/${assessmentId}/questionnaire/${assessmentQuestionnaireId}/response/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_SUCCESS,
          payload: response?.data?.Data?.Rows || [],
        });
        if (callback) {
          callback(response?.data?.Data?.Rows || []);
        }
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_FAILURE,
        payload: getErrorObject(err),
      });
    }
  } else {
    try {
      const res = await axios.get(`assessment-questionnaires/${assessmentQuestionnaireId}/requirements/${requirementId}/questions`);
      dispatch({
        type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_SUCCESS,
        payload: res.data.Data,
      });
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_FAILURE,
        payload: getErrorPayload(err),
      });
    }
  }

};

export const PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_LOADING =
  '@@assessmentQuestionnaire/PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_LOADING';
export const PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_SUCCESS =
  '@@assessmentQuestionnaire/PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_SUCCESS';
export const PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FAILURE =
  '@@assessmentQuestionnaire/PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FAILURE';

export const saveAssessmentQuestionnaireRequirementsResponses = (formData, isRequirementGroup = NO_VALUE) => async (dispatch) => {
  const { assessmentId, assessmentQuestionnaireId, questionId, assessmentQuestionnaireQuestionResponseId, parentAssessmentId, assessmentRequestId } = formData;
  dispatch({ type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_LOADING });
  const body = JSON.stringify({
    Active: true,
    IsNew: true,
    AssessmentQuestionnaireQuestionResponseId: assessmentQuestionnaireQuestionResponseId,
    AssessmentQuestionnaireId: assessmentQuestionnaireId,
    QuestionnaireTemplateQuestionId: formData?.questionnaireTemplateQuestionId,
    QuestionId: questionId,
    QuestionResponseCategoryId: formData?.questionResponseCategoryId,
    AssessmentId: assessmentId,
    RequirementId: formData?.requirementId,
    QuestionResponseText: formData?.questionResponseText,
    QuestionResponseComment: formData?.questionResponseComment,
    QuestionResponseModeId: formData?.questionResponseModeId,
    CanOrgUploadEvidence: formData?.canOrgUploadEvidence,
  });

  try {
    const res = await axios.put(
      `assessment/${assessmentId}/questionnaire/${assessmentQuestionnaireId}/question/${questionId}/response/${assessmentQuestionnaireQuestionResponseId}`,
      body
    );
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_SUCCESS,
        payload: res?.data?.Data,
      });
      if (isRequirementGroup === YES_VALUE) {
        dispatch(getAssessmentQuestionnaireRequirementGroup(assessmentQuestionnaireId));
      } else {
        dispatch(getAssessmentQuestionnaireRequirement(assessmentQuestionnaireId));
      }
      dispatch(getAssessmentQuestionnaireResponse(assessmentId, assessmentQuestionnaireId, formData?.requirementId));
      dispatch(setSelectedRequirementId(formData?.requirementId));
      dispatch(searchAssessmentOverviewPortalAssessmentDetails(parentAssessmentId, assessmentRequestId, assessmentQuestionnaireId));
    }
  } catch (err) {
    dispatch({
      type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_CARD = '@@assessmentQuestionnaire/SET_SELECTED_CARD';

export const setSelectedCard =
  (value = {}) =>
    async (dispatch) => {
      dispatch({
        type: SET_SELECTED_CARD,
        payload: value,
      });
    };
export const ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_LOADING = '@@assessmentQuestionnaire/ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_LOADING';
export const ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_SUCCESS = '@@assessmentQuestionnaire/ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_SUCCESS';
export const ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE = '@@assessmentQuestionnaire/ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE';

export const attachEvidenceToRequirement = (combinedList, assessmentQuestionnaireQuestionResponseId, selectedRequirement, callback, isRequirementGroup = NO_VALUE, assessmentRequestId = null, isSaveSingleQuestion) => async (dispatch) => {
  const { AssessmentQuestionnaireId, AssessmentId, RequirementId } = selectedRequirement;
  dispatch({
    type: ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_LOADING,
  });

  let body = new FormData();

  combinedList.forEach((item, index) => {
    Object.keys(item).forEach((key) => {
      if (key === 'UploadedFile') {
        body.append(`modelList[${index}].${key}`, item[key]);
      } else {
        body.append(`modelList[${index}].${key}`, JSON.stringify(item[key].toString()));
      }
    });
  });

  try {
    const res = await axios.post(
      `/vendor/product/assessment/assessmentquestionnairequestionresponse/${assessmentQuestionnaireQuestionResponseId}/attachement`,
      body,
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }
    );
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_SUCCESS,
        payload: res?.data?.Data,
        evidence: EVIDENCE_ATTACH,
      });
      if (callback) {
        callback();
      }
      if (!isSaveSingleQuestion) {
        if (isRequirementGroup === YES_VALUE) {
          dispatch(getAssessmentQuestionnaireRequirementGroup(AssessmentQuestionnaireId));
        } else {
          dispatch(getAssessmentQuestionnaireRequirement(AssessmentQuestionnaireId));
        }
        dispatch(getAssessmentQuestionnaireResponse(AssessmentId, AssessmentQuestionnaireId, RequirementId));
        // call assessment search...
        if (!isNil(assessmentRequestId)) {
          dispatch(searchAssessmentOverviewPortalAssessmentDetails(AssessmentId, assessmentRequestId, AssessmentQuestionnaireId));
        }
      }
    }
  } catch (err) {
    dispatch({
      type: ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const SEARCH_EVIDENCE_DETAILS_LOADING = '@@assessmentQuestionnaire/SEARCH_EVIDENCE_DETAILS_LOADING';
export const SEARCH_EVIDENCE_DETAILS_SUCCESS = '@@assessmentQuestionnaire/SEARCH_EVIDENCE_DETAILS_SUCCESS';
export const SEARCH_EVIDENCE_DETAILS_FAILURE = '@@assessmentQuestionnaire/SEARCH_EVIDENCE_DETAILS_FAILURE';

export const searchEvidenceDetails = (searchData) => async (dispatch) => {
  dispatch({ type: SEARCH_EVIDENCE_DETAILS_LOADING });

  let body = getEvidenceDetailsBody(searchData);
  try {
    const response = await axios.post(`/vendor/evidence/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_EVIDENCE_DETAILS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_EVIDENCE_DETAILS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_EVIDENCE_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_EVIDENCE_DOCUMENTS_LOADING = '@@assessmentQuestionnaire/DELETE_EVIDENCE_DOCUMENTS_LOADING';
export const DELETE_EVIDENCE_DOCUMENTS_SUCCESS = '@@assessmentQuestionnaire/DELETE_EVIDENCE_DOCUMENTS_SUCCESS';
export const DELETE_EVIDENCE_DOCUMENTS_FAILURE = '@@assessmentQuestionnaire/DELETE_EVIDENCE_DOCUMENTS_FAILURE';

export const deleteEvidenceDocument = (evidenceId, callback) => async (dispatch) => {
  dispatch({ type: DELETE_EVIDENCE_DOCUMENTS_LOADING });

  try {
    const response = await axios.delete(`/vendor/evidence/${evidenceId}`);

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_EVIDENCE_DOCUMENTS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: DELETE_EVIDENCE_DOCUMENTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: DELETE_EVIDENCE_DOCUMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_COMMENTED_VALUE_ON_BLUR = '@@assessmentQuestionnaire/SET_COMMENTED_VALUE_ON_BLUR';

export const setCommentedValueOnBlur =
  (value = null) =>
    async (dispatch) => {
      dispatch({
        type: SET_COMMENTED_VALUE_ON_BLUR,
        payload: value,
      });
    };

export const SET_SELECTED_ASSESSMENT_QUESTIONNAIRE = '@@assessmentQuestionnaire/SET_SELECTED_ASSESSMENT_QUESTIONNAIRE';

export const setSelectedAssessmentQuestionnaire =
  (value = {}) =>
    async (dispatch) => {
      dispatch({
        type: SET_SELECTED_ASSESSMENT_QUESTIONNAIRE,
        payload: value,
      });
    };

export const DELETE_EVIDENCE_ATTACHMENTS_LOADING = '@@assessmentQuestionnaire/DELETE_EVIDENCE_ATTACHMENTS_LOADING';
export const DELETE_EVIDENCE_ATTACHMENTS_SUCCESS = '@@assessmentQuestionnaire/DELETE_EVIDENCE_ATTACHMENTS_SUCCESS';
export const DELETE_EVIDENCE_ATTACHMENTS_FAILURE = '@@assessmentQuestionnaire/DELETE_EVIDENCE_ATTACHMENTS_FAILURE';

export const deleteEvidenceDocumentFromResponse = (evidenceFile, assessmentQuestionnaireId, assessmentId, requirementId, isRequirementGroup = NO_VALUE, assessmentRequestId = null, callback, isSaveSingleQuestion) => async (dispatch) => {
  dispatch({ type: DELETE_EVIDENCE_ATTACHMENTS_LOADING });

  try {
    const response = await axios.delete(
      `/vendor/product/assessment/assessmentquestionnairequestionresponse/${evidenceFile.AssessmentQuestionnaireQuestionResponseId}/attachement/evidence/${evidenceFile.AssessmentQuestionnaireQuestionResponseAttachmentId}`
    );

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_EVIDENCE_ATTACHMENTS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: DELETE_EVIDENCE_ATTACHMENTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
        evidence: EVIDENCE_DELETE,
      });
      if (callback) {
        callback();
      }
      if (!isSaveSingleQuestion) {
        if (isRequirementGroup === YES_VALUE) {
          dispatch(getAssessmentQuestionnaireRequirementGroup(assessmentQuestionnaireId));
        } else {
          dispatch(getAssessmentQuestionnaireRequirement(assessmentQuestionnaireId));
        }
        dispatch(getAssessmentQuestionnaireResponseInitializeForm(assessmentId, assessmentQuestionnaireId, requirementId));
        dispatch(setSelectedRequirementId(requirementId));
        // call assessment search...
        if (!isNil(assessmentRequestId)) {
          dispatch(searchAssessmentOverviewPortalAssessmentDetails(assessmentId, assessmentRequestId, assessmentQuestionnaireId));
        }
      }
    }
  } catch (err) {
    dispatch({
      type: DELETE_EVIDENCE_ATTACHMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_REQUIREMENT_ID = '@@assessmentQuestionnaire/SET_SELECTED_REQUIREMENT_ID';

export const setSelectedRequirementId =
  (id = null) =>
    async (dispatch) => {
      dispatch({
        type: SET_SELECTED_REQUIREMENT_ID,
        payload: id,
      });
    };

export const SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING = '@@assessmentOverview/SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING';
export const SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS = '@@assessmentOverview/SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS';
export const SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE = '@@assessmentOverview/SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE';

export const submitAssessmentQuestionnaireRequirement = (formData, props, showSummary, callback) => async (dispatch) => {
  dispatch({ type: SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING });
  const body = {
    ...formData,
  };

  try {
    const res = await axios.put(`/assessment/assessmentquestionnairerequirement/${formData?.AssessmentQuestionnaireRequirementId}`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
      });
    } else {
      dispatch({
        type: SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
      });
      if (showSummary) {
        dispatch(setSelectedRequirement({}));
      } else if (props) {
        dispatch(setSelectedRequirementId(props?.nextRequirement?.RequirementId));
        dispatch(setSelectedRequirement(props?.nextRequirement, false));
      }
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_RESPONSE_SAVED_STATUS = '@@assessmentQuestionnaire/SET_RESPONSE_SAVED_STATUS';

export const setResponseSavedStatus =
  (autoSaveLoading = false) =>
    async (dispatch) => {
      dispatch({
        type: SET_RESPONSE_SAVED_STATUS,
        payload: autoSaveLoading,
      });
    };

export const SET_REQUIREMENT_LIST = '@@assessmentQuestionnaire/SET_REQUIREMENT_LIST';

export const setRequirementList =
  (list = []) =>
    async (dispatch) => {
      dispatch({
        type: SET_REQUIREMENT_LIST,
        payload: list,
      });
    };

export const SET_LOADING_EXPANDED_SECTIONS = '@assessmentQuestionnaire/SET_LOADING_EXPANDED_SECTIONS';

export const setLoadingExpandedSections =
  (payload = false) =>
    async (dispatch) => {
      dispatch({
        type: SET_LOADING_EXPANDED_SECTIONS,
        payload,
      });
    };

export const SEARCH_SHOW_ME_REDIRECTION_MODAL_LOADING = '@@assessmentQuestionnaire/SEARCH_SHOW_ME_REDIRECTION_MODAL_LOADING';
export const SEARCH_SHOW_ME_REDIRECTION_MODAL_SUCCESS = '@@assessmentQuestionnaire/SEARCH_SHOW_ME_REDIRECTION_MODAL_SUCCESS';
export const SEARCH_SHOW_ME_REDIRECTION_MODAL_FAILURE = '@@assessmentQuestionnaire/SEARCH_SHOW_ME_REDIRECTION_MODAL_FAILURE';

export const searchShowMeRedirectionModallDetails =
  (searchData = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_SHOW_ME_REDIRECTION_MODAL_LOADING });

      let body = getShowMeRedirectionModallDetailsBody(searchData);
      try {
        const response = await axios.post(`/assessment/legacyassessmentquestionnaire/search`, body);

        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
          dispatch(setErrors(errors));
          dispatch({
            type: SEARCH_SHOW_ME_REDIRECTION_MODAL_FAILURE,
            payload: {},
          });
        } else {
          dispatch({
            type: SEARCH_SHOW_ME_REDIRECTION_MODAL_SUCCESS,
            payload: response?.data?.Data?.Rows,
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_SHOW_ME_REDIRECTION_MODAL_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SET_SHOW_ME_REDIRECTION_MODAL = '@assessmentQuestionnaire/SET_SHOW_ME_REDIRECTION_MODAL';

export const setShowMeRedirectionModal =
  (emptyRedirectionModal = {}) =>
    async (dispatch) => {
      dispatch({
        type: SET_SHOW_ME_REDIRECTION_MODAL,
        payload: emptyRedirectionModal,
      });
    };

export const SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_LOADING = '@@assessmentQuestionnaire/SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_LOADING';
export const SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_SUCCESS = '@@assessmentQuestionnaire/SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_SUCCESS';
export const SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_FAILURE = '@@assessmentQuestionnaire/SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_FAILURE';

export const submitAssessmentRedirectionDetails = (formData) => async (dispatch) => {
  dispatch({ type: SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_LOADING });
  try {
    const res = await axios.post(`/assessment/legacyassessmentquestionnaire`, formData);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_FAILURE,
      });
    } else {
      dispatch({
        type: SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_SUCCESS,
      });
    }
  } catch (err) {
    dispatch({
      type: SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_CO_PILOT_GENERATED_RESPONSES_LOADING = '@@assessmentQuestionnaire/SEARCH_CO_PILOT_GENERATED_RESPONSES_LOADING';
export const SEARCH_CO_PILOT_GENERATED_RESPONSES_SUCCESS = '@@assessmentQuestionnaire/SEARCH_CO_PILOT_GENERATED_RESPONSES_SUCCESS';
export const SEARCH_CO_PILOT_GENERATED_RESPONSES_FAILURE = '@@assessmentQuestionnaire/SEARCH_CO_PILOT_GENERATED_RESPONSES_FAILURE';

export const searchCoPilotGeneratedResponses =
  (assessmentId = null, questionnaireId = null, questionId = null, searchData = {}) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_CO_PILOT_GENERATED_RESPONSES_LOADING });

      try {
        const response = await axios.post(
          `/assessment/${assessmentId}/questionnaire/${questionnaireId}/question/${questionId}/action/generateresponse`,
          searchData
        );

        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
          dispatch(setErrors(errors));
          dispatch({
            type: SEARCH_CO_PILOT_GENERATED_RESPONSES_FAILURE,
            payload: {},
          });
        } else {
          dispatch({
            type: SEARCH_CO_PILOT_GENERATED_RESPONSES_SUCCESS,
            payload: response?.data?.Data,
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_CO_PILOT_GENERATED_RESPONSES_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SET_EXPANDED_CO_PILOT_SECTIONS = '@assessmentQuestionnaire/SET_EXPANDED_CO_PILOT_SECTIONS';

export const setExpandedCoPilotSection =
  (payload = null) =>
    async (dispatch) => {
      dispatch({
        type: SET_EXPANDED_CO_PILOT_SECTIONS,
        payload,
      });
    };

export const SEARCH_ASSESSMENT_BY_ID_LOADING = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_BY_ID_LOADING';
export const SEARCH_ASSESSMENT_BY_ID_SUCCESS = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_BY_ID_SUCCESS';
export const SEARCH_ASSESSMENT_BY_ID_FAILURE = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_BY_ID_FAILURE';

export const searchAssessmentsById = (assessmentId) => async (dispatch) => {
  dispatch({
    type: SEARCH_ASSESSMENT_BY_ID_LOADING,
  });

  const body = searchAssessmentByIdBody(assessmentId);
  try {
    const res = await axios.post(`/legacy/assessment/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_BY_ID_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_BY_ID_SUCCESS,
        payload: res?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_BY_ID_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_COPILOT_RESPONSE_FOR_SELECTED_DRU = '@assessmentQuestionnaire/SET_COPILOT_RESPONSE_FOR_SELECTED_DRU';

export const setCopilotResponseForSelectedDRU =
  (data = {}) =>
    async (dispatch) => {
      dispatch({
        type: SET_COPILOT_RESPONSE_FOR_SELECTED_DRU,
        payload: data,
      });
    };

export const RESET_ASSESSMENT_QUESTIONNAIRE_LOADING = '@@assessmentQuestionnaire/RESET_ASSESSMENT_QUESTIONNAIRE_LOADING';
export const RESET_ASSESSMENT_QUESTIONNAIRE_SUCCESS = '@@assessmentQuestionnaire/RESET_ASSESSMENT_QUESTIONNAIRE_SUCCESS';
export const RESET_ASSESSMENT_QUESTIONNAIRE_FAILURE = '@@assessmentQuestionnaire/RESET_ASSESSMENT_QUESTIONNAIRE_FAILURE';

export const resetAssessmentQuestionnaire = (assessmentId, assessmentQuestionnaireId, questionnaireQuestionResponseResetTypeId, callback) => async (dispatch) => {
  dispatch({
    type: RESET_ASSESSMENT_QUESTIONNAIRE_LOADING,
  });

  const body = getResetQuestionnaireBody(assessmentQuestionnaireId, questionnaireQuestionResponseResetTypeId, assessmentId);

  try {
    const res = await axios.post(`/assessment/${assessmentId}/questionnaire/questionresponse/action/reset`, body, {
      timeout: GENERATE_COPILOT_RESPONSE_TIMEOUT,
    });
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: RESET_ASSESSMENT_QUESTIONNAIRE_FAILURE,
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: RESET_ASSESSMENT_QUESTIONNAIRE_SUCCESS,
      });

      if (callback) {
        callback(questionnaireQuestionResponseResetTypeId);
      }
    }
  } catch (err) {
    dispatch({
      type: RESET_ASSESSMENT_QUESTIONNAIRE_FAILURE,
    });
  }
};


export const PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_LOADING =
  '@@assessmentQuestionnaire/PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_LOADING';
export const PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_SUCCESS =
  '@@assessmentQuestionnaire/PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_SUCCESS';
export const PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_FAILURE =
  '@@assessmentQuestionnaire/PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_FAILURE';

export const saveAssessmentQuestionnaireRequirementsResponsesForceSave = (formData, showToaster = false, isRequirementGroup = NO_VALUE, props = {}, callback) => async (dispatch) => {
  const { assessmentId, assessmentQuestionnaireId, questionId, assessmentQuestionnaireQuestionResponseId, parentAssessmentId, assessmentRequestId } = formData;
  dispatch({ type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_LOADING });
  const body = JSON.stringify({
    Active: true,
    IsNew: true,
    AssessmentQuestionnaireQuestionResponseId: assessmentQuestionnaireQuestionResponseId,
    AssessmentQuestionnaireId: assessmentQuestionnaireId,
    QuestionnaireTemplateQuestionId: formData?.questionnaireTemplateQuestionId,
    QuestionId: questionId,
    QuestionResponseCategoryId: formData?.questionResponseCategoryId,
    AssessmentId: assessmentId,
    RequirementId: formData?.requirementId,
    QuestionResponseText: formData?.questionResponseText,
    QuestionResponseComment: formData?.questionResponseComment,
    QuestionResponseModeId: formData?.questionResponseModeId,
    CanOrgUploadEvidence: formData?.canOrgUploadEvidence,
  });

  try {
    const res = await axios.put(
      `assessment/${assessmentId}/questionnaire/${assessmentQuestionnaireId}/question/${questionId}/response/${assessmentQuestionnaireQuestionResponseId}`,
      body
    );
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_SUCCESS,
        payload: res?.data?.Data,
        showToaster: showToaster,
      });
      if (showToaster) {
        if (!props?.isSaveSingleQuestion) {
          if (isRequirementGroup === YES_VALUE) {
            dispatch(getAssessmentQuestionnaireRequirementGroup(assessmentQuestionnaireId));
          } else {
            dispatch(getAssessmentQuestionnaireRequirement(assessmentQuestionnaireId));
          }

          if (props?.showSummaryScreen) {
            dispatch(setSelectedRequirement({}));
          } else if (!isEmpty(props?.nextRequirement)) {
            dispatch(setSelectedRequirement(props?.nextRequirement, false));
          }
        }

        dispatch(
          searchAssessmentOverviewPortalAssessmentDetails(parentAssessmentId, assessmentRequestId, assessmentQuestionnaireId, props?.isVendorCopilot, {
            hideLoading: props?.isSaveSingleQuestion,
          })
        );
        dispatch(setEditedQuestionIndexSet());
      }

      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_REQUIREMENT_QUESTIONS = '@assessmentQuestionnaire/SET_SELECTED_REQUIREMENT_QUESTIONS';

export const setSelectedRequirementQuestions = (value = []) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_REQUIREMENT_QUESTIONS,
    payload: value,
  });
};

export const SET_REQUIREMENT_SUMMARY_TYPE_TAB = '@@assessmentQuestionnaire/SET_REQUIREMENT_SUMMARY_TYPE_TAB';

export const setRequirementSummaryTypeTab =
  (value = REQUIREMENT_QUESTIONS_TAB_OPTIONS.ALL) =>
    async (dispatch) => {
      dispatch({
        type: SET_REQUIREMENT_SUMMARY_TYPE_TAB,
        payload: value,
      });
    };

export const SET_REQUIREMENT_SUMMARY_TAB = '@@assessmentQuestionnaire/SET_REQUIREMENT_SUMMARY_TAB';

export const setRequirementSummaryTab =
  (value = REQUIREMENTS_SUMMARY_TAB_OPTIONS.GRID) =>
    async (dispatch) => {
      dispatch({
        type: SET_REQUIREMENT_SUMMARY_TAB,
        payload: value,
      });
    };

export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE';

export const getAssessmentQuestionnaireRequirementGroup =
  (assessmentQuestionnaireId = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING });
      const body = getAssessmentQuestionnaireRequirementGroupSearchBody(assessmentQuestionnaireId);
      try {
        const response = await axios.post(`/assessment/questionnaire/requirement/group/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_LOADING = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_LOADING';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_SUCCESS = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_SUCCESS';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_FAILURE = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_FAILURE';

export const getAssessmentQuestionnaireResponseInitializeForm = (assessmentId, assessmentQuestionnaireId, requirementId) => async (dispatch, getState) => {
  dispatch({ type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_LOADING });
  const requirementList = getState().assessmentQuestionnaire.requirementList;
  const isRequirementGroup = requirementList[0]?.isRequirementGroup === YES_VALUE;

  let requirementGroupId = null;
  if (isRequirementGroup) {
    requirementGroupId = requirementList?.find(reqGrp => reqGrp.RequirementList?.find((it) => it.RequirementId === Number(requirementId)))?.RequirementGroupId;
    const body = getAssessmentQuestionnaireResponseBody(requirementId, false, requirementGroupId);
    try {
      const response = await axios.post(`assessment/${assessmentId}/questionnaire/${assessmentQuestionnaireId}/response/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_SUCCESS,
          payload: response?.data?.Data?.Rows || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_FAILURE,
        payload: getErrorObject(err),
      });
    }
  }
  else {
    try {
      const res = await axios.get(`assessment-questionnaires/${assessmentQuestionnaireId}/requirements/${requirementId}/questions`);
      dispatch({
        type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_SUCCESS,
        payload: res.data.Data,
      });
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_FAILURE,
        payload: getErrorPayload(err),
      });
    }
  }
};

export const RESET_EVIDENCE_FLAG = '@@assessmentQuestionnaire/RESET_EVIDENCE_FLAG';

export const resetEvidenceFlag = () => async (dispatch) => {
  dispatch({
    type: RESET_EVIDENCE_FLAG,
  });
};

export const EDITED_QUESTION_INDEX_SET = '@@assessmentQuestionnaire/EDITED_QUESTION_INDEX_SET';

export const setEditedQuestionIndexSet = (index = null) => async (dispatch) => {
  dispatch({
    type: EDITED_QUESTION_INDEX_SET,
    payload: index,
  });
};

export const RESET_REQUIREMENTS_LIST = '@@assessmentQuestionnaire/RESET_REQUIREMENTS_LIST';

export const resetRequirementsList = () => async (dispatch) => {
  dispatch({
    type: RESET_REQUIREMENTS_LIST,
  });
};

export const SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_LOADING = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_LOADING';
export const SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_SUCCESS = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_SUCCESS';
export const SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_FAILURE = '@@assessmentQuestionnaire/SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_FAILURE';

export const getAssessmentEntireQuestionnaireResponse = (assessmentQuestionnaireId, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_LOADING });
  try {
    const response = await axios.get(`assessment-questionnaires/${assessmentQuestionnaireId}/questions?includeResOptions=true`);
    dispatch({
      type: SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_SUCCESS,
      payload: response?.data?.Data || [],
    });

    if (callback) {
      callback(response?.data?.Data || []);
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_FAILURE,
      payload: getErrorPayload(err),
    });
  }
};


export const SET_CURRENT_QUESTIONNAIRE_INDEX = '@assessmentQuestionnaire/SET_CURRENT_QUESTIONNAIRE_INDEX';

export const setCurrentQuestionnaireIndex = (index) => async (dispatch) => {
  dispatch({
    type: SET_CURRENT_QUESTIONNAIRE_INDEX,
    payload: index
  });
};

export const SET_SELECTED_DOCUMENT_LIST = '@assessmentQuestionnaire/SET_SELECTED_DOCUMENT_LIST';

export const setSelectDocumentList = (list) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_DOCUMENT_LIST,
    payload: list
  });
};

export const RESET_LOCAL_FORM_DATA = '@assessmentQuestionnaire/RESET_LOCAL_FORM_DATA';
export const resetLocalFormData = (payload = {}) => async (dispatch) => {
  dispatch({
    type: RESET_LOCAL_FORM_DATA,
    payload,
  });
}
